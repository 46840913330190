import React from 'react'
import './skills.css';
import UIDesign from '../../assets/graphicsicon.png';
import WebDesign from '../../assets/webicon.png';
import AppDesign from '../../assets/typerwritingicon.png'

const skills = () => {
  return (
<section id = 'skills'>
    <span className='skillTitle'>what i do</span>
    <span className='skillDesc'> I am a web and graphics designer who excels in crafting aesthetically pleasing and user-friendly websites. With a keen eye for design and a strong understanding of user experience, I create digital interfaces that engage and inspire. My expertise lies in transforming ideas into visually captivating web designs that deliver exceptional user experiences.

</span>
<div className='skillBars'>
<div className='skillBar'>
<img src={WebDesign} width="100" height="100" alt='uidesign' className='skillBarImg'/>
<div className='skillBarText'>
<h2>Website design</h2>
<p>
I'm a dedicated website designer with a flair for combining aesthetics and functionality to create engaging digital experiences.</p>

</div>
</div>

<div className='skillBar'>
<img src={UIDesign} width="100" height="100" alt="webdesign" className="skillBarImg"/>
<div className='skillBarText'>
<h2>Graphics Design</h2>
<p>I am a passionate graphics designer who brings creativity and innovation to life through visual storytelling.</p>

</div>
</div>

<div className='skillBar'>
<img src={AppDesign} width="100" height="100" alt='appdesign' className='skillBarImg'/>
<div className='skillBarText'>
<h2>TypeWriting</h2>
<p>I'm a skilled typist who takes pride in precision and speed, transforming words into documents with efficiency and accuracy.</p>


</div>
</div>
</div>

</section>
  )
}

export default skills