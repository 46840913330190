import React from 'react'
import './intro.css';
import nati from '../../assets/nati.jpg'

import { Link } from 'react-router-dom';

const Intro = () => {
  return (
   <section id = "intro">
<div className = "introContent">
              <span  className='hello'><br/><br/>    <br/> </span>
  <span className='introtext'>I am   <span className='introName'>Natnael Belayneh</span><br/>Web and Graphics Designer</span>

<p className='intropara'>i am skilled in web and graphic design, turning ideas into visually captivating digital experiences. </p>
<img src ={nati}  alt="photo" className='bg'></img>
</div>


    </section>

  );
}

export default Intro